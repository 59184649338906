


function isInt(value) {
    if (value.indexOf(".") == (value.length - 1)) {
        return false;
    }
    if (isNaN(value)) {
        return false;
    }
    var x = parseFloat(value);
    return (x | 0) === x;
}
function isFloat(n) {
    if (n.indexOf(".") == (n.length - 1)) {
        return false;
    }
    return n !== "" && !isNaN(n) && Math.round(n) !== n;
}
function getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min)) + min; //The maximum is exclus$
}
function handleError($scope, message) {
    //console.error(message);
    
    // if('string' == typeof message){
    //     $scope.ErrorMessage = message;
    // }
    if (message && message.error && message.error.message)
        $scope.ErrorMessage =  message.error.message 
    //$scope.ErrorMessage = message;
    // if (message.statusText)
    //     $scope.ErrorMessage = message.statusText + '\n';
    if (message.message)
         $scope.ErrorMessage = message.message + '\n';
    // if (message.stack)
    //  $scope.ErrorMessage = message.message + '\n' + message.stack;
/*
    if (message.data) {
        if (message.data.error) {
            $scope.ErrorMessage = '';
            if (message.data.error.message) {
                $scope.ErrorMessage = message.data.error.message + '\n';
            }
        
        }
    }
    */

    if($scope.ErrorMessage == '')
    {
            $scope.ErrorMessage = "some thing went wrong"
    }
/*
    if (message.stack)
        $scope.ErrorMessage = message.message + '\n' + message.stack;
    if (message.data) {
        if (message.data.error) {
            $scope.ErrorMessage = '';
            if (message.data.error.message) {
                $scope.ErrorMessage = message.data.error.message + '\n';
            }
            ;
            if (message.data.error.innererror) {
                if (message.data.error.innererror.message) {
                    $scope.ErrorMessage += message.data.error.innererror.message + '\n';
                }
                ;
                if (message.data.error.innererror.internalexception) {
                    if (message.data.error.innererror.internalexception.message) {
                        $scope.ErrorMessage += message.data.error.innererror.internalexception.message + '\n';
                    }
                    ;
                    if (message.data.error.innererror.internalexception.internalexception) {
                        if (message.data.error.innererror.internalexception.internalexception.message) {
                            $scope.ErrorMessage += message.data.error.innererror.internalexception.internalexception.message + '\n';
                        }
                        ;
                    }
                }
            }
        }
        if (message.data.ExceptionType) {
            $scope.ErrorMessage += message.data.ExceptionType + '\n' + message.data.ExceptionMessage + '\n';
        }
    }
    */
    if (!$scope.$$phase) {
        $scope.$apply();
    }
}

function GetErrorMessage(message) {
    var ErrorMessage = "";
    
    if (message && message.error && message.error.message)
        ErrorMessage =  message.error.message 
        

    else if (message.message)
        ErrorMessage = message.message + '\n';

    else if(message)
        ErrorMessage = message;
    
    if(ErrorMessage == '')
    {
        ErrorMessage = "some thing went wrong"
    }
    return ErrorMessage;
}


function validateJSON(body) {
    try {
      var data = JSON.parse(body);
      // if came to here, then valid
      return data;
    } catch(e) {
      // failed to parse
      return null;
    }
  }

function clearError($scope) {
    $scope.ErrorMessage = null;
}


function TodayDate() : string{
    var today :any = new Date();
    var dd :any = today.getDate();
    var mm :any = today.getMonth()+1; //January is 0!
    var yyyy = today.getFullYear();
    if(dd<10){
            dd='0'+dd
        } 
        if(mm<10){
            mm='0'+mm
        } 

        return yyyy+'-'+mm+'-'+dd;
}

    var $BODY = $('body');
    var $MENU_TOGGLE = $('#menu_toggle');
    var $SIDEBAR_MENU = $('#sidebar-menu');
    var $SIDEBAR_FOOTER = $('.sidebar-footer');
    var $LEFT_COL = $('.left_col');
    var $RIGHT_COL = $('.right_col');
    var $NAV_MENU = $('.nav_menu');
    var $FOOTER = $('footer');


var getDivValues = function(){
     $BODY = $('body');
     $MENU_TOGGLE = $('#menu_toggle');
     $SIDEBAR_MENU = $('#sidebar-menu');
     $SIDEBAR_FOOTER = $('.sidebar-footer');
     $LEFT_COL = $('.left_col');
     $RIGHT_COL = $('.right_col');
     $NAV_MENU = $('.nav_menu');
     $FOOTER = $('footer');
}

var setContentHeight = function () {

    $BODY = $('body');
    $MENU_TOGGLE = $('#menu_toggle');
    $SIDEBAR_MENU = $('#sidebar-menu');
    $SIDEBAR_FOOTER = $('.sidebar-footer');
    $LEFT_COL = $('.left_col');
    $RIGHT_COL = $('.right_col');
    $NAV_MENU = $('.nav_menu');
    $FOOTER = $('footer');
    /*
	// reset height
	$RIGHT_COL.css('min-height', $(window).height());

	var bodyHeight = $BODY.outerHeight(),
		footerHeight = $BODY.hasClass('footer_fixed') ? -10 : $FOOTER.height(),
		leftColHeight = $LEFT_COL.eq(1).height() + $SIDEBAR_FOOTER.height(),
		contentHeight = bodyHeight < leftColHeight ? leftColHeight : bodyHeight;

	// normalize content
	contentHeight -= $NAV_MENU.height() + footerHeight;

	$RIGHT_COL.css('min-height', contentHeight);
    */
};


var opensubmenu = function(ev) { 
      var $li = $(ev).parent();

      if(!$li.find('ul').is(":visible")){
            $SIDEBAR_MENU.find( "li ul" ).slideUp();
            $('ul:first', $li).slideDown(function() {
                          setContentHeight();
                      });

        }else{
            $('ul:first', $li).slideUp(function() {
                setContentHeight();
            });
        }


    //////////////////////////////// old
    //   if ($li.is('.active')) {

    //             $li.removeClass('active active-sm');

    //         $('ul:first', $li).slideUp(function() {
    //             setContentHeight();
    //         });
         
    //     //   $('ul:first', $li).slideUp(function() {
    //     //       setContentHeight();
    //     //   });
    //   } else {
    //       // prevent closing menu if we are on child menu
    //       if (!$li.parent().is('.child_menu')) {
    //           $SIDEBAR_MENU.find('li').removeClass('active active-sm');
    //           $SIDEBAR_MENU.find('li ul').slideUp();
    //       }else
    //       {
    //           if ( $BODY.is( ".nav-sm" ) )
    //           {
    //               $SIDEBAR_MENU.find( "li" ).removeClass( "active active-sm" );
    //               $SIDEBAR_MENU.find( "li ul" ).slideUp();
    //           }
    //       }
    //       $li.addClass('active');

    //       $('ul:first', $li).slideDown(function() {
    //           setContentHeight();
    //       });
    //   }
  }



  // toggle small or large menu 
$MENU_TOGGLE.on('click', function() { 
 
$BODY.toggleClass('ng-scope nav-md');

setContentHeight();


})





// check active menu

// $SIDEBAR_MENU.find('a[href="' + CURRENT_URL + '"]').parent('li').addClass('current-page')

